import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

class Testimonials extends Component {

  render() {
    if(this.props.data){
      var testimonials = this.props.data.testimonials.map(function(testimonials){
        return (
          <li key={testimonials.user}>
            <blockquote>
              <p>{testimonials.text}</p>
              <cite>{testimonials.user}</cite>
            </blockquote>
          </li>
        )
      })
    }

    return (
      <section id="testimonials">
        <div className="text-container">
          <div className="row">
            <div className="two columns header-col">
              <h1><FontAwesomeIcon icon={faQuoteLeft} /><span>Testimonios</span></h1>
            </div>
            <div className="ten columns flex-container">
              <ul className="slides">
                {testimonials}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonials;
